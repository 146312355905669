<template>
<div class="row row-eq-height justify-content-around">
  <div class="col-lg-3">
    <a href="http://www.mlab.phys.waseda.ac.jp/">
      <img alt="Wab Logo" src="../../assets/logo.png" class="img-fluid rounded">
      <p>{{ lang == 'en' ? 'Morishima Lab.' : '森島研究室' }}</p>
    </a>
  </div>
  <div class="col-lg-3">
    <a href="https://www.waseda.jp/fsci/about/departments/advanced/">
      <img alt="ASE Logo" src="../../assets/ase_logo.png" class="img-fluid rounded">
      <p>{{ lang == 'en' ? 'Advanced Science and Engineering' : '先進理工学研究科' }}</p>
    </a>
  </div>
  <div class="col-lg-3">
    <a href="https://www.waseda.jp/top/">
      <img alt="Waseda Logo" src="../../assets/waseda.png" class="img-fluid rounded">
      <p>{{ lang == 'en' ? 'Waseda University' : '早稲田大学' }}</p>
    </a>
  </div>
</div>
</template>

<script>
export default {
  name: 'Affiliation',
  computed: {
    lang: function(){
      return this.$route.query.lang == 'en' ? 'en'  : 'ja'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
p
{
  text-align: center;
}

img
{
    object-fit: contain;
    height: 220px;
    width: auto;
}

@media screen and (max-width: 992px)
{
  img
  {
    object-fit: contain;
    height: 20vh;
    width: auto;
  }
}

</style>
