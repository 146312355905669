<template>
<div class="container my-4 my-lg-5">
  <div class="row justify-content-center">
    <div class="col-12 col-md-10">
      <News/>
    </div>
  </div>
  <div class="row justify-content-center mt-4">
    <div class="col-12 col-md-10">
      <Agenda/>
    </div>
  </div>
  <div class="row justify-content-center mt-5">
    <div class="col-12 col-md-10">
      <Affiliation/>
    </div>
  </div>
</div>
</template>

<script>
// @ is an alias to /src
import News from '@/components/home/News.vue'
import Agenda from '@/components/home/Agenda.vue'
import Affiliation from '@/components/home/Affiliation.vue'

export default {
  name: 'Home',
  data: function () {
    return {
      lang: this.$route.query.lang == 'en' ? 'en' : 'ja'
    }
  },
  components: {
    News,
    Agenda,
    Affiliation
  }
}
</script>