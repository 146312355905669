import { createApp } from 'vue'
import App from './App.vue'
import router from './router'


import firebase from 'firebase'

// production
var firebaseConfig = {
  apiKey: 'AIzaSyDXUGU3navJgoJpLiiNkSMSLy98aETIbUg',
  authDomain: 'mlab-test-f7414.firebaseapp.com',
  projectId: 'mlab-test-f7414',
  storageBucket: 'mlab-test-f7414.appspot.com',
  messagingSenderId: '307998183017',
  appId: '1:307998183017:web:3730b35310a0fe13d31cf8',
};

// // staging
// const firebaseConfig = {
//   apiKey: "AIzaSyB7OC5Ronjc26VkeBOHw7oeQzkkIFlvBeI",
//   authDomain: "mlab-staging-b3b89.firebaseapp.com",
//   projectId: "mlab-staging-b3b89",
//   storageBucket: "mlab-staging-b3b89.appspot.com",
//   messagingSenderId: "917231672928",
//   appId: "1:917231672928:web:770d3aa002329808b0ad05"
// };

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

createApp(App).use(router).mount('#app')
