<template>
<div class="container-xl px-0">
  <div class="row justify-content-end mt-3 mt-md-5 mr-3 lang">
    <a href="https://www.youtube.com/user/morishimalab/featured" type="button" class="btn btn-outline-danger">
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-youtube" viewBox="0 0 16 16">
        <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"></path>
      </svg>
      Youtube
    </a>
    <p class="col-auto"><i class="fas fa-globe icon"></i>Language:</p>
    <div class="dropdown">
      <button class="btn btn-outline-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        {{ disp_lang }}
      </button>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <a class="dropdown-item" :href="`${current_path}`">日本語</a>
        <a class="dropdown-item" :href="`${current_path}?lang=en`">English</a>
      </div>
    </div>
  </div>
  <!-- {{ lang }} -->
  <!-- <div>{{ this.$route.query.lang }}</div> -->

  <nav class="navbar navbar-expand-lg px-0 mb-3 navbar-custom" role="navigation">
    <router-link class="navbar-brand ml-3 mr-0 mr-lg-3 pb-0" :to="`/${url_lang}`"><img alt="MorishimaLab logo" src="./assets/morishima_logo.png" height="40"></router-link>
    <button class="navbar-toggler mr-3" type="button" data-toggle="collapse" data-target="#collapsibleNavbar" aria-controls="collapsibleNavbar" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse justify-content-center" id="collapsibleNavbar">
      <div class="navbar-nav nav-pills nav-justified nav-fill w-100 mx-0 mx-lg-3">
        <router-link class="nav-item nav-link py-0" :to="`/${url_lang}`" exact-active-class="active" data-toggle="collapse" data-target=".navbar-collapse.show">Home</router-link>
        <router-link class="nav-item nav-link py-0" :to="`/members${url_lang}`" exact-active-class="active" data-toggle="collapse" data-target=".navbar-collapse.show">Members</router-link>
        <router-link class="nav-item nav-link py-0" :to="`/publications${url_lang}`" exact-active-class="active" data-toggle="collapse" data-target=".navbar-collapse.show">Publications</router-link>
        <router-link class="nav-item nav-link py-0" :to="`/contact${url_lang}`" exact-active-class="active" data-toggle="collapse" data-target=".navbar-collapse.show">Contact</router-link>
        <router-link class="nav-item nav-link py-0" :to="`/projects${url_lang}`" exact-active-class="active" data-toggle="collapse" data-target=".navbar-collapse.show">Projects</router-link>
        <!-- <router-link class="nav-item nav-link py-0" :to="`/signin${url_lang}`" exact-active-class="active" data-toggle="collapse" data-target=".navbar-collapse.show">Signin</router-link>
        <router-link class="nav-item nav-link py-0" :to="`/db${url_lang}`" exact-active-class="active" data-toggle="collapse" data-target=".navbar-collapse.show">DB</router-link> -->
      </div>
    </div>
  </nav>
</div>

<main role="main"><router-view/></main>
</template>

<script>
export default {
  name: 'App',
  computed: {
    lang: function(){
      return this.$route.query.lang == 'en' ? 'en'  : 'ja'
    },
    disp_lang: function(){
      return this.lang == 'en' ? 'English'  : '日本語'
    },
    current_path: function(){
      return this.$route.path
    },
    url_lang: function(){
      return this.lang == 'en' ? '?lang=en'  : ''
      // return this.lang == 'en' ? `${path}?lang=en` : path
    }
  }
}
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

main > hr
{
  max-width: 800px;
  margin-top: 15px;
  margin-bottom: 25px;
  border-width: 1px;
  border-color: #979797;
}

.icon
{
  margin: 0 1rem;
}
</style>

<style scoped>

.lang > p
{
  margin-bottom: 0;
  display: flex;
  align-items: center;
}

.navbar-custom 
{
  color: #2c3e50;

}

.navbar-custom .navbar-toggler 
{
    color: rgba(0,0,0,.5);
    border-color: rgba(0,0,0,.1);
}

.navbar-custom .navbar-toggler-icon 
{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar-custom .navbar-nav
{
  line-height: 3.5rem;
}

.navbar-custom .navbar-nav .nav-link
{
  /* リンクカラーを変更 */
  color: #2c3e50;
  border-radius: 0;
  border-top: 3px solid transparent;
}

.navbar-custom .navbar-nav .nav-link.active
{
  background-color: #f8f9fa;
}

@media screen and (min-width: 992px)
{
  /* 通常表示のactive,hover時のアニメーション */
  .navbar-custom .navbar-nav .nav-link
  {
    position: relative;
    display: inline-block;
    transition: .3s;
  }

  .navbar-custom .navbar-nav .nav-link:not(.active)::after
  {
    position: absolute;
    bottom: 0;
    left: 50%;
    content: '';
    width: 0;
    height: 2px;
    background-color: #42b983;
    transition: .3s;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .navbar-custom .navbar-nav .nav-link.active
  {
    border-top: 3px solid #2c3e50;
  }

  .navbar-custom .navbar-nav .nav-link:hover::after
  {
    width: 100%;
  }
}
</style>
