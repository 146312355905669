<template>
<h1 class="text-lg-left">Research Agenda</h1>
<hr>
<div class="row row-eq-height">
  <div class="col-lg-7">
    <p v-if="lang === 'ja'">
      物理学を応用して人々に幸福をもたらすこと、安全・安心な社会、豊かな生活、日本の伝統文化などあらゆる面で、先端技術を通して貢献するというのが、森島研の研究目標です。あくまで最終のターゲットは人間の幸福や豊かな感性・文化に向けられているというのが特徴であり、研究者の自己満足に終わらせないことが重要なスタンスです。具体的には、物理、数学を道具だてとして使いこなし、ディジタル信号処理（SP）、コンピュータビジョン（CV）、コンピュータグラフィックス（CG）、ヒューマンコンピュータインタラクション(HCI)などの最先端の研究テーマを通して、世界に技術力をアピールするだけではなく、実際に目に見えるコンテンツや具体的に役に立つシステムとして具現化し、産業界に貢献していきます。
    </p>
    <p v-else-if="lang === 'en'">
      The research goal of the Morishima Laboratory is to contribute through advanced technology to all aspects of society, including the application of physics to bring happiness to people, a safe and secure society, a rich lifestyle, and traditional Japanese culture. Our research is characterized by the fact that our ultimate target is human happiness and enrichment of sensibilities and culture, and it is important not to end up with the self-satisfaction of researchers. Specifically, we use physics and mathematics as tools, and through cutting-edge research themes such as digital signal processing (SP), computer vision (CV), computer graphics (CG), and human-computer interaction (HCI), we not only appeal to the world with our technological capabilities, but also contribute to industry by embodying them in the form of tangible content and concretely useful systems.
    </p>
  </div>
  <div class="col-lg-5">
    <img alt="Agenda Image" src="../../assets/agenda_img.jpg" class="img-fluid rounded">
  </div>
</div>
</template>

<script>
export default {
  name: 'Agenda',
  computed: {
    lang: function(){
      return this.$route.query.lang == 'en' ? 'en'  : 'ja'
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
p
{
  text-align: left;
}
</style>
