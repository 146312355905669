<template>
<div class="card">
  <h2 class="card-header text-left">News</h2>
  <div class="list-group list-group-flush news">
    <li class="list-group-item list-group-item-action flex-column align-items-start" v-for="a_news in this.news" :key="a_news.id">
      <div class="d-flex w-100 justify-content-between">
          <p class="mb-1 text-left"><a :href="a_news.url">{{ lang == 'en' ? a_news.description_en : a_news.description }}</a></p> 
          <small>{{ a_news.year }} / {{ a_news.month }} / {{ a_news.day }}</small>
      </div>
    </li>
  </div>
</div>
</template>

<script>
import firebase from 'firebase'

export default {
  name: 'News',
  data: function () {
    return {
      db: null,
      news: []
    }
  },
  created: function () {
    this.db = firebase.firestore()

    var _this = this
    this.db.collection('news').orderBy('year', 'desc').orderBy('month', 'desc').orderBy('day', 'desc').onSnapshot(function (querySnapshot) {
      _this.news = []
      querySnapshot.forEach(function (doc) {
        var data = doc.data()
        _this.news.push(data)
      })
    })
  },
  computed: {
    lang: function(){
      return this.$route.query.lang == 'en' ? 'en'  : 'ja'
    }
  }
}
</script>

<style>
.news
{
  max-height: 17vh;
  overflow: auto;
}
</style>